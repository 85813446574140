<template>
  <div class="be-card h-100">
    <div class="position-relative">
      <header-with-divider
        :color="smallSize ? 'blue center' : 'blue'"
        :text="$t('profile.merchant_payment_list')"
      />
    </div>
    <loading v-if="loading" />
    <div
      v-else-if="!loading && merchantPayments && merchantPayments.length"
      class="scroll-wrapper"
    >
      <be-table
        :data="merchantPayments"
        :fields="smallSize ? smallTableFields : fields"
        :smallSize="smallSize"
        rowClasses="py-3 mh-40"
      >
        <template v-slot:type="{ row }">
          <div class="d-flex">
            <i
              :class="[operationIcon(row.type), operationClass(row.type)]"
              class="op-icon"
            />
            <span>
              {{ row.status }}
            </span>
          </div>
        </template>
        <template v-slot:status="{ row }">
          <span
            :class="[
              smallSize
                ? statusColorClass(row.status)
                : activeClass(row.status),
              smallSize ? '' : 'status',
            ]"
          >
            {{ row.status }}
          </span>
        </template>
        <template v-slot:desc="{ row }">
          <label class="f-14" v-html="row.desc" />
          <small class="d-block text-secondary">
            <div v-if="!smallSize">
              <span class="f-w-600"> Ref :</span>
              {{ row.status }}
            </div>
          </small>
        </template>
        <template v-slot:base_amount="{ row }">
          <div class="d-flex flex-column f-w-600">
            {{ row.base_amount + " " + row.currency }}
            <small
              v-if="!smallSize"
              :class="statusColorClass(row.status)"
              class="f-w-600"
            >
              {{ row.status }}
            </small>
          </div>
        </template>
        <template v-slot:frais="{ row }">
          <div>
            <div class="d-flex flex-column">
              <span class="f-w-600">
                {{ row.frais }}%
                <span v-if="smallSize"
                  >- {{ row.fee_amount + " " + row.currency }}</span
                >
              </span>
              <small v-if="!smallSize" class="op-8">
                {{ $("profile.fees") }}:
                {{ row.fee_amount + " " + row.currency }}
              </small>
            </div>
          </div>
        </template>

        <template v-slot:operator="{ row }">
          <div class="d-flex flex-column">
            <img
              :src="row.information.operator && row.information.operator.logo"
              alt=""
              width="70"
            />
          </div>
        </template>
        <template v-slot:created_at="{ row }">
          <div v-if="smallSize">
            {{ row.created_at | moment("Do MMMM YYYY H:mm:ss") }}
          </div>
          <div v-else class="d-flex flex-column">
            <span>{{ row.created_at | moment("Do MMMM YYYY") }}</span>
            <small>{{ row.created_at | moment("H:mm:ss") }}</small>
          </div>
        </template>

        <template v-slot:actions="{ row }">
          <div @click.stop="openModal(row)">
            <i :class="smallSize && 'd-block'" class="icon-arrow-right" />
          </div>
        </template>
      </be-table>

      <b-modal ref="detailsModal" no-fade size="lg" top>
        <div class="text-center">
          <b-row>
            <b-col>
              <div class="label-text">
                <div>{{ $t("profile.merchant") }}</div>
                <div>
                  {{ modalData && modalData.application.label }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("profile.payment_amount") }}</div>
                <div>
                  {{
                    modalData &&
                      `${modalData.base_amount} ${modalData.currency}`
                  }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.status") }}</div>
                <div :class="modalData && statusColorClass(modalData.status)">
                  {{ modalData && modalData.status }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.creation_date") }}</div>
                <div>
                  {{ modalData && modalData.created_at }}
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <template slot="modal-header">
          <span />
          <span class="modal-title">
            {{ $t("profile.payment_details") }} -
            {{ modalData && modalData.payment_id }}
          </span>
          <span @click="hideModal">
            <i class="icon-close f-24" />
          </span>
        </template>
        <template slot="modal-footer">
          <template v-if="showCreateTicketForm">
            <button class="ml-4 be-btn lg blue" @click="hideModal">
              {{ $t("common.close") }}
            </button>
            <button
              class="ml-4 be-btn primary lg danger"
              @click="$router.push({ name: 'assistance' })"
            >
              {{ $t("common.create_a_ticket") }}
            </button>
          </template>
          <button v-else class="ml-4 be-btn primary lg blue" @click="hideModal">
            {{ $t("common.close") }}
          </button>
        </template>
      </b-modal>

      <BasePagination
        :current_page="current_page"
        :last_page="last_page"
        @page="getMerchantPaymentList"
      />
    </div>
    <no-content
      v-else
      icon="icon-shop"
      text="$t('profile.no_merchant_payment_done')"
    />
    <div v-if="isDetailsOpen" class="bg-secondary">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { emptyListModel, operationStatus } from "../../helpers/constants";
import _ from "lodash";
import BeTable from "../../components/BeTable";
import Loading from "../../components/common/Loading";
import NoContent from "../../components/common/NoContent";
import HeaderWithDivider from "../../components/common/HeaderWithDivider";

export default {
  name: "MerchantPayment",
  components: { BeTable, HeaderWithDivider, Loading, NoContent },
  data() {
    return {
      loading: true,
      modalData: null,
      paymentField: {
        users_id: null,
        paginate: 10,
        search: false,
        keyword: null,
      },
      smallTableFields: [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "base_amount",
        },
        {
          label: this.$t("profile.merchant"),
          key: "app_name",
        },
        {
          label: this.$t("common.reference"),
          key: "payment_id",
        },
        {
          label: "",
          key: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState("auth", ["metaMerchantPayment"]),
    ...mapGetters("auth", ["merchantPayments", "current_page", "last_page"]),
    ...mapState("auth", ["user"]),
    ...mapState({
      sidebarState: state => state.sidebarState,
    }),
    isDetailsOpen() {
      return this.$route.name === "payment.detail";
    },
    listModel() {
      return emptyListModel.merchantPayment;
    },
    fields() {
      return [
        {
          label: this.$t("common.status").toUpperCase(),
          key: "status",
        },
        {
          label: this.$t("common.date").toUpperCase(),
          key: "created_at",
        },
        {
          label: this.$t("common.amount").toUpperCase(),
          key: "base_amount",
        },
        {
          label: this.$t("profile.merchant").toUpperCase(),
          key: "app_name",
        },
        {
          label: this.$t("common.reference"),
          key: "payment_id",
        },
        {
          label: "",
          key: "actions",
          class: "col-1 d-flex align-items-center justify-content-end",
        },
      ];
    },
    smallSize() {
      return this.$screen.width - (this.sidebarState ? 320 : 100) < 992;
    },
    showCreateTicketForm() {
      return this.modalData && this.modalData.status !== operationStatus.failed;
    },
  },
  created() {
    this.paymentField.users_id = this.user.id;
    this.getMerchantPaymentList(1);
  },
  methods: {
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    search: _.debounce(async function(text) {
      this.paymentField.keyword = text;
      await this.getMerchantPaymentList(1);
    }, 200),
    getMerchantPaymentList(page, cancel = false) {
      this.loading = true;
      if (this.paymentField.keyword) this.paymentField.search = true;
      else {
        this.paymentField.keyword = null;
        this.paymentField.search = false;
      }
      this.$store
        .dispatch("auth/getMerchantPaymentList", {
          page: page,
          cancel: cancel,
          field: this.paymentField,
        })
        .then(() => {
          this.loading = false;
        });
    },
    operationIcon(type) {
      switch (type) {
        case "Crédit":
          return "icon-deposit-fill";
        case "Débit":
          return "icon-withdraw-fill";
      }
    },
    operationClass(type) {
      switch (type) {
        case "Crédit":
          return "be-text-success";
        case "Débit":
          return "be-text-failure";
      }
    },
    activeClass(status) {
      switch (status) {
        case operationStatus.success:
          return "badge-success";
        case operationStatus.failed:
          return "badge-failure";
        case "En cours":
          return "badge-info";
      }
    },
    statusColorClass(status) {
      switch (status) {
        case operationStatus.success:
          return "be-text-success";
        case operationStatus.failed:
          return "be-text-failure";
        case operationStatus.pending:
          return "be-text-encours";
      }
    },
  },
};
</script>

<style scoped></style>
